import React, { useState } from "react";
import { connect } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from "reactstrap";
import classnames from "classnames";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import "../../Builder/Form/InputFormStyle.scss";
import Const from "../../Constants";

import {
  onInputChangeAction,
  showPickupFormAction,
  dismissPickupFormAction,
  onInputResetAction,
  setDataTableSortingAction,
} from "../../Store/Actions";

import {
  InputForm,
  BSelect,
  DateTimePickerForm,
  RadioboxForm,
} from "../../Builder/Form";

const PickupsSearchCard = (props) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleRoleFilter = (val) => {
    let e = {
      target: {
        name: "status_id",
        value: val,
      },
    };
    switch (val) {
      case "Pending":
        props.setDataTableSortingAction(props.formName, "contact_date", "desc");
        props.onInputChangeAction(props.formName, e);
        break;
      case "Scheduled":
      case "Collected":
        props.setDataTableSortingAction(
          props.formName,
          "collection_date",
          "desc"
        );
        props.onInputChangeAction(props.formName, e);
        break;
      default:
        props.setDataTableSortingAction(props.formName, "name", "desc");
        props.onInputChangeAction(props.formName, e);
    }
  };

  return (
    <>
      <div className="custom_nav_tabs_wrapper_style card_wrapper_style pt-2">
        <div className="d-flex justify-content-between">
          <div>
            <Nav className="flex-column flex-md-row" tabs>
              {Const.PickupsFilterOptions.map((status) => (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        status.value === props.data[props.formName].status_id,
                    })}
                    onClick={() => handleRoleFilter(status.value)}
                  >
                    {status.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>

          <div>
            <div className="d-flex">
              <div>
                <label
                  className="btn search_btn_style mr-2"
                  type="button"
                  onClick={handleToggleFilter}
                >
                  <SearchOutlinedIcon />
                </label>
              </div>

              <div>
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  onClick={() => props.showPickupFormAction()}
                >
                  <PersonAddOutlinedIcon />
                  <p>Add new pickup</p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Collapse isOpen={showFilter}>
          <div className="mt-3">
            <div className="row">
              <div className="col-12 col-md-6 mb-2">
                <BSelect
                  name="donor_id"
                  formName={props.formName}
                  keepDefaultStyle
                  inputContainerStyle="select-def-input-container"
                  containerStyle=" "
                  rootStyle=""
                  // isMulti
                  isClearable
                  optionLabel="name"
                  optionValue="id"
                  options={props.data?.pickupsFiler?.donorOptions}
                  placeholder="Select Donors"
                />
              </div>

              <div className="col-12 col-md-6 mb-2">
                <BSelect
                  name="collector_id"
                  formName={props.formName}
                  keepDefaultStyle
                  inputContainerStyle="select-def-input-container"
                  containerStyle=" "
                  rootStyle=""
                  // isMulti
                  isClearable
                  optionLabel="name"
                  optionValue="id"
                  options={props.data?.pickupsFiler?.collectorOptions}
                  placeholder="Select Collectors"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 mb-2">
                <InputForm
                  name="donor_phone"
                  placeholder="Donor Phone"
                  containerStyle=" "
                  inputStyle="input-style-default"
                  formName={props.formName}
                />
              </div>

              <div className="col-12 col-md-6 mb-2">
                <InputForm
                  name="name"
                  placeholder="Collection ID"
                  containerStyle=" "
                  inputStyle="input-style-default"
                  formName={props.formName}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <DateTimePickerForm
                  // rootStyle="flex-fill"
                  dateTime
                  hasIcon={false}
                  name="collection_date_from"
                  placeholderText="collection date / From"
                  containerStyle="py-0"
                  datePickerContainer="w-100"
                  inputStyle="date-picker-input-default"
                  formName={props.formName}
                  isClearable
                />
              </div>
              <div className="col-12 col-md-6 mb-2 ">
                <DateTimePickerForm
                  // rootStyle="flex-fill"
                  dateTime
                  hasIcon={false}
                  name="collection_date_to"
                  placeholderText="collection date / to"
                  containerStyle="py-0"
                  datePickerContainer="w-100"
                  inputStyle="date-picker-input-default"
                  formName={props.formName}
                  isClearable
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-2">
                <BSelect
                  name="city_id"
                  formName={props.formName}
                  keepDefaultStyle
                  inputContainerStyle="select-def-input-container"
                  containerStyle=" "
                  rootStyle=""
                  isMulti
                  optionLabel="name"
                  optionValue="id"
                  options={Const.Cities}
                  placeholder="Select City"
                />
              </div>

              <div className="col-12 col-md-6 mb-2">
                <BSelect
                  name="area_id"
                  formName={props.formName}
                  keepDefaultStyle
                  inputContainerStyle="select-def-input-container"
                  containerStyle=" "
                  rootStyle=""
                  isMulti
                  optionLabel="name"
                  optionValue="id"
                  options={Const.Areas}
                  placeholder="Select Area"
                />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="mt-3 search-time-range">
        <RadioboxForm
          name="time_range"
          formName={props.formName}
          optionLabel="value"
          optionValue="value"
          options={[
            { label: "All", value: "All" },
            { value: "Today", label: "Today" },
            { label: "Upcoming", value: "Upcoming" },
            { value: "Overdue", label: "Overdue" },
          ]}
          dependOn="status_id"
          dependancyType="equal"
          dependancyValue={["Pending", "Scheduled"]}
          optionsContainerStyle="row mb-2 mt-1 mx-xl-2 ml-md-0"
          optionItemStyle="d-flex pl-3 text-nowrap"
          optionInputStyle="optionInputStyle"
          optionLabelActiveStyle="active-radio-filter"
          optionLabelStyle="form-check-label"
        />
        <RadioboxForm
          name="time_range"
          formName={props.formName}
          optionLabel="value"
          optionValue="value"
          options={[
            { label: "All", value: "All" },
            { value: "Today", label: "Today" },
          ]}
          dependOn="status_id"
          dependancyType="equal"
          dependancyValue={["Collected"]}
          optionsContainerStyle="row mb-2 mt-1 mx-xl-2 ml-md-0"
          optionItemStyle="d-flex pl-3 text-nowrap"
          optionInputStyle="optionInputStyle"
          optionLabelActiveStyle="active-radio-filter"
          optionLabelStyle="form-check-label"
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps, {
  onInputChangeAction,
  showPickupFormAction,
  dismissPickupFormAction,
  onInputResetAction,
  setDataTableSortingAction,
})(PickupsSearchCard);
