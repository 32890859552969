import React from "react";
import { connect } from "react-redux";
import { showUserFormAction } from "../../Store/Actions";

const CollectorInfoCard = ({ name, email, phone, id, role, ...props }) => {
  const handleEditCollector = () => {
    let collector = {
      name,
      email,
      phone,
      id,
      role_id: role.id,
    };
    props.showUserFormAction("Edit", collector);
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h1 className="mb-0">{name}</h1>
        </div>

        {props.authUser?.role?.id !=="5" ?
        <div>
          <button
            className="btn edit_btn_style"
            type="button"
            onClick={handleEditCollector}
          >
            Edit
          </button>
        </div>:null}
      </div>

      <div>
        <p>{phone}</p>
      </div>

      <div>
        <p>{email}</p>
      </div>

      <hr />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.userProfile,

  };
};

export default connect(mapStateToProps, { showUserFormAction })(CollectorInfoCard);
