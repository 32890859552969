import React from "react";

const LoginLayout = (props) => {
  return (
    <div className="login-body-wrapper">
      <div className="d-flex flex-column justify-content-center align-items-center vh-100">
        <div className="row">
          <img
            src={require("../../Assets/img/ataya_logo.png").default}
            className="img-fluid mb-5"
            width="450"
            height="auto"
          />
        </div>

        <div className="row w-md-75 w-lg-25">{props.children}</div>
      </div>
    </div>
  );
};

export default LoginLayout;
