import React, { useState, useEffect } from "react";
import {
  faPencilAlt,
  faPowerOff,
  faComments,
  faExclamationCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";

import { Link, useRouteMatch, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Store from "../../Store";

import DonorPickupsSearchCard from "../../Components/DonorPickupsSearchCard";
import Pagination from "../../Components/Pagination";
import {
  fetchDonorPickupsListAction,
  setDataTableSortingAction,
  onInputResetAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import PickupModal from "../../Components/PickupModal";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Loader from "../../Components/Loader";

const renderStatusCell = ({ status, schedule_note, cancellation_note }) => {
  switch (status.name) {
    case "Cancelled":
      return (
        <div className={`${status.name}_status_style`}>
          <span>{status.name}</span>
          <Tooltip
            classes={{ popper: "custom_tooltip_wrapper_style" }}
            title={cancellation_note}
            placement="left"
            arrow
          >
            <span className="btn">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </span>
          </Tooltip>
        </div>
      );

      break;
    case "Scheduled":
      return (
        <div className={`${status.name}_status_style`}>
          <span>{status.name}</span>
          {Boolean(schedule_note) && (
            <Tooltip
              classes={{ popper: "custom_tooltip_wrapper_style" }}
              title={schedule_note}
              placement="left"
              arrow
            >
              <span className="btn">
                <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
              </span>
            </Tooltip>
          )}
        </div>
      );

      break;

    default:
      return (
        <div className={`${status.name}_status_style`}>
          <span>{status.name}</span>
        </div>
      );
      break;
  }
};

const DonorPickupsList = (props) => {
  const columns = [
    {
      name: "collection ID",
      wrap: true,
      selector: "name",
      sortable: true,
      grow: 0.75,
      cell: (row) =>
        props.user.role.id == "1" ? (
          <span role="button" onClick={() => showPickupModal(row)}>
            {row.name}
          </span>
        ) : (
          `${row.name}`
        ),
    },
    {
      name: "Collector",
      wrap: true,
      selector: "collector",
      sortable: false,
      grow: 1,
      cell: (row) => (row?.collector ? `${row?.collector?.name}` : null),
      // <span className="btn pl-3 ml-1" onClick={() => console.log("row")}>
      //   <FontAwesomeIcon color="#35a992" icon={faPlus} />
      // </span>
    },
    {
      name: "area",
      wrap: true,
      sortable: false,
      grow: 0.5,
      cell: (row) => `${row.address?.area?.name ?? ""}`,
    },
    {
      name: "city",
      wrap: true,
      sortable: false,
      grow: 0.5,
      cell: (row) => `${row.address?.area?.city?.name ?? ""}`,
    },
    {
      name: "Created At",
      wrap: true,
      selector: "created_at",
      sortable: false,
      grow: 1,
      cell: (row) =>
        Boolean(row?.created_at)
          ? moment(row?.created_at).format("DD-MM-yyyy hh:mm a")
          : "",
    },
    {
      name: "collected date",
      wrap: true,
      selector: "collection_date",
      sortable: false,
      grow: 1,
      cell: (row) =>
        Boolean(row?.collection_date)
          ? moment(row?.collection_date).format("DD-MM-yyyy hh:mm a")
          : "",
    },
    {
      name: "status",
      wrap: true,
      selector: "status",
      sortable: false,
      grow: 0.63,
      cell: (row) => renderStatusCell(row),
      // renderStatusCell(status, schedule_note, cancellation_note),
    },
  ];
  // Toggle function for pickup Modal
  const [pickupModal, setPickupModal] = useState({
    isOpen: false,
    pickupLogs: [],
    name: "",
  });
  const dismissPickupModal = () => {
    if (pickupModal.isOpen) {
      setPickupModal({
        isOpen: false,
        pickupLogs: [],
        name: "",
      });
    }
  };

  const showPickupModal = (pickup) => {
    setPickupModal({
      isOpen: true,
      pickupLogs: pickup.logs,
      name: pickup.name,
    });
  };

  const dataTableRef = "donorPickupsList";
  const { donorId } = useParams();
  useEffect(() => {
    // props.fetchDonorPickupsListAction({
    //   ref: dataTableRef,
    //id:donorId,
    //   [dataTableRef]: props.data[dataTableRef],
    // });
    return () => {
      props.onInputResetAction(dataTableRef, "name");
      props.onInputResetAction(dataTableRef, "city_id");
      props.onInputResetAction(dataTableRef, "area_id");
      props.onInputResetAction(dataTableRef, "status_id");
      props.onInputResetAction(dataTableRef, "collector_id");
    };
  }, []);

  const handleFilter = () => {
    props.fetchDonorPickupsListAction({
      ref: dataTableRef,
      id: donorId,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };
  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].name,
    props.data[dataTableRef].status_id,
    props.data[dataTableRef].collector_id,
    props.data[dataTableRef].city_id,
    props.data[dataTableRef].area_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchDonorPickupsListAction({
      ref: dataTableRef,
      id: donorId,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  const handleSorting = (field, dir) => {
    props.fetchDonorPickupsListAction({
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
        sorting: {
          dir,
          key: field.selector,
        },
      },
      ref: dataTableRef,
      id: donorId,
    });
    props.setDataTableSortingAction(dataTableRef, field.selector, dir);
  };

  return (
    <div>
      <PickupModal
        ModalIsOpen={pickupModal.isOpen}
        toggleModal={dismissPickupModal}
        pickup_name={pickupModal.name}
        pickup_timeline_data={pickupModal.pickupLogs}
      />
      <DonorPickupsSearchCard formName={dataTableRef} />
      <div className="mt-3">
        <DataTable
          className="general-data-list donor_pickup_list_wrapper_style"
          columns={columns}
          data={props.data[dataTableRef].data}
          noHeader
          persistTableHead
          sortServer
          onSort={handleSorting}
          defaultSortAsc={props.data[dataTableRef].sorting.dir == "asc"}
          defaultSortField={props.data[dataTableRef].sorting.key}
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=""
              onPaginate={handlePaginate}
            />
          )}
          pagination={true}
          paginationServer={true}
          progressPending={props.data[dataTableRef].isLoading}
          progressComponent={<Loader />}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    user: state.auth.userProfile,
  };
};

export default connect(mapStateToProps, {
  fetchDonorPickupsListAction,
  setDataTableSortingAction,
  onInputResetAction,
})(DonorPickupsList);
