import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  fetchCollectorProfileAction,
  resetCollectorProfileAction,
} from "../../Store/Actions";
import CollectorProfile from "../../Containers/CollectorProfile";
import UserForm from "../../Containers/UsersList/UserForm";

import { useParams } from "react-router-dom";
import CollectionsList from "../../Containers/CollectionsList";
import BalanceForm from "../../Containers/CollectorProfile/BalanceForm";
import AddBalanceForm from "../../Containers/CollectorProfile/AddBalanceForm";
import EditPickupCollection from "../../Containers/CollectorProfile/EditPickupCollection";

const ViewCollectorProfile = (props) => {
  const { collectorId } = useParams();
  useEffect(() => {
    props.fetchCollectorProfileAction(collectorId);
    return () => {
      props.resetCollectorProfileAction();
    };
  }, []);

  return (
    <>
      <CollectorProfile />
      <CollectionsList />
      <UserForm />
      <BalanceForm />
      <AddBalanceForm />
      <EditPickupCollection/>
    </>
  );
};

export default connect(null, {
  fetchCollectorProfileAction,
  resetCollectorProfileAction,
})(ViewCollectorProfile);
