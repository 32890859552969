import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import Types from "../Actions/types";
import swal from "sweetalert";

import { loginSuccess, loginFailed } from "../Actions";

import { authUser } from "../../Services/Client";

export function* handleLogingInSaga({ payload }) {
  console.log(payload);
  try {
    const userAuth = yield call(authUser, payload);
    yield put(
      loginSuccess(
        userAuth.data?.login?.access_token,
        userAuth?.data?.login?.user
      )
    );
  } catch (error) {
    console.log("err", { error });
    yield put(loginFailed());
    swal({
      title: "login error",
      text:
        error?.graphQLErrors[0]?.extensions?.reason ?? "Something went wrong!",
      icon: "error",
      timer: 2000,
      buttons: false,
    });
  }
}

function* onLogingInAttempt() {
  yield takeEvery(Types.LOGING_IN_ATTEMPT, handleLogingInSaga);
}

export default {
  onLogingInAttempt,
};
