import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./Assets/style/main.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ProtectedRouter from "./Helpers/HOC/ProtectedRouter";
import UnauthorizedPage from "./Routes/UnauthorizedPage";

import { connect } from "react-redux";
import history from "./Helpers/Navigations/history";

import Layout from "./Components/Layout";
import ViewUsersList from "./Routes/ViewUsersList";
import ViewPickupsList from "./Routes/ViewPickupsList";
import ViewDonorsList from "./Routes/ViewDonorsList";
import PrivacyPolicyPage from "./Routes/PrivacyPolicyPage";
import LoginPage from "./Routes/LoginPage";
import ForgotPasswordPage from "./Routes/ForgotPasswordPage";
import ResetPasswordPage from "./Routes/ResetPasswordPage";
import ViewCollectorProfile from "./Routes/ViewCollectorProfile";
import ViewDonorProfile from "./Routes/ViewDonorProfile";

const MainApp = (props) => {
  return (
    <Router history={history}>
      {Boolean(props.token) ? (
        <Switch>
          {/* <Route exact path="/privacy-policy">
            <PrivacyPolicyPage />
          </Route> */}
          <Route exact path="/login">
            <Redirect to="/" />
          </Route>

          <ProtectedRouter roles={["1", "2", "5"]} exact path="/">
            {props.user?.role?.id == 1 || props.user?.role?.id == 5 ? (
              <Redirect to="/users" />
            ) : (
              <Redirect to="/pickups" />
            )}
          </ProtectedRouter>
          <Layout>
            <ProtectedRouter roles={["1", "5"]} exact path="/users">
              <ViewUsersList />
            </ProtectedRouter>
            <ProtectedRouter roles={["1", "2"]} exact path="/pickups">
              <ViewPickupsList />
            </ProtectedRouter>
            <ProtectedRouter roles={["1", "2"]} exact path="/donors">
              <ViewDonorsList />
            </ProtectedRouter>
            <ProtectedRouter
              roles={["1", "2", "5"]}
              exact
              path="/collector-profile/:collectorId"
            >
              <ViewCollectorProfile />
            </ProtectedRouter>
            <ProtectedRouter
              roles={["1", "2"]}
              exact
              path="/donor-profile/:donorId"
            >
              <ViewDonorProfile />
            </ProtectedRouter>
            <Route path="/unauthorized">
              <UnauthorizedPage />
            </Route>
          </Layout>
        </Switch>
      ) : (
        <Switch>
          {/* <Route exact path="/privacy-policy">
            <PrivacyPolicyPage />
          </Route> */}
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPasswordPage />
          </Route>
          <Route exact path="/password/reset/:token">
            <ResetPasswordPage />
          </Route>
          <Route exact path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      )}
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.userProfile,
  };
};

export default connect(mapStateToProps)(MainApp);
