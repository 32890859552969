const UserStatusOptions = [
  { label: "All", value: null },
  { label: "Pending", value: "Pending" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const UserRolesFilterOptions = [
  { label: "All", value: null },
  { label: "Admin", value: "Admin" },
  { label: "CSR", value: "CSR" },
  { label: "Collector", value: "Collector" },
  { label: "Balance Settler", value: "BALANCE_SETTLER" },
];

const PickupsFilterOptions = [
  { label: "All", value: null },
  { label: "Pending", value: "Pending" },
  { label: "Scheduled", value: "Scheduled" },
  { label: "Collected", value: "Collected" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Unassigned Pickups", value: "Unassigned" },
];

const UserRolesOptions = [
  { label: "Admin", value: "1" },
  { label: "CSR", value: "2" },
  { label: "Collector", value: "3" },
  { label: "Balance Settler", value: "5" },
];

const Cities = [
  {
    id: "1",
    name: "Aswan",
  },
  {
    id: "2",
    name: "Asyut",
  },
  {
    id: "3",
    name: "Luxor",
  },
  {
    id: "4",
    name: "Alexandria",
  },
  {
    id: "5",
    name: "Ismailia",
  },
  {
    id: "6",
    name: "Red Sea",
  },
  {
    id: "7",
    name: "Beheira",
  },
  {
    id: "9",
    name: "Dakahlia",
  },
  {
    id: "10",
    name: "Suez",
  },
  {
    id: "11",
    name: "Sharqia",
  },
  {
    id: "12",
    name: "Gharbia",
  },
  {
    id: "13",
    name: "Cairo",
  },
  {
    id: "14",
    name: "Qalyubia",
  },
  {
    id: "15",
    name: "Monufia",
  },
  {
    id: "16",
    name: "Minya",
  },
  {
    id: "17",
    name: "Al Wady El Gedid",
  },
  {
    id: "18",
    name: "Beni Suef",
  },
  {
    id: "19",
    name: "Port Said",
  },
  {
    id: "20",
    name: "South Sinai",
  },
  {
    id: "21",
    name: "Damietta",
  },
  {
    id: "22",
    name: "Sohag",
  },
  {
    id: "23",
    name: "North Sinai",
  },
  {
    id: "24",
    name: "Qena",
  },
  {
    id: "25",
    name: "Kafr El-Sheikh",
  },
  {
    id: "26",
    name: "Marsa Matruh",
  },
];
const Areas = [
  { id: "1", name: "Abu El-Reesh", cityId: "1" },
  { id: "2", name: "Abu Simbel", cityId: "1" },
  { id: "3", name: "Edfu", cityId: "1" },
  { id: "4", name: "El Basaliya", cityId: "1" },
  { id: "5", name: "El Radisia", cityId: "1" },
  { id: "6", name: "El Sebaiya", cityId: "1" },
  { id: "7", name: "Daraw", cityId: "1" },
  { id: "8", name: "Sahara", cityId: "1" },
  { id: "9", name: "Kalabsha", cityId: "1" },
  { id: "10", name: "Kom Ombo", cityId: "1" },
  { id: "11", name: "Madinat Aswan", cityId: "1" },
  { id: "12", name: "Nasr El Noba", cityId: "1" },
  { id: "13", name: "Abnob", cityId: "2" },
  { id: "14", name: "Abu Tig", cityId: "2" },
  { id: "15", name: "Asyut Al Gadidah", cityId: "2" },
  { id: "16", name: "El Badari", cityId: "2" },
  { id: "17", name: "El Ghanayem", cityId: "2" },
  { id: "18", name: "El fath", cityId: "2" },
  { id: "19", name: "El Quseyya", cityId: "2" },
  { id: "20", name: "Dairut", cityId: "2" },
  { id: "21", name: "Sahel Selim", cityId: "2" },
  { id: "22", name: "Sidfa", cityId: "2" },
  { id: "23", name: "Madinat Asyut", cityId: "2" },
  { id: "24", name: "Manfalut", cityId: "2" },
  { id: "25", name: "Armant", cityId: "3" },
  { id: "26", name: "Esna", cityId: "3" },
  { id: "27", name: "Al Bayadeyah", cityId: "3" },
  { id: "28", name: "Al Zeineyah", cityId: "3" },
  { id: "29", name: "El Tod", cityId: "3" },
  { id: "30", name: "Al Qarnah", cityId: "3" },
  { id: "31", name: "Madinat Luxor", cityId: "3" },
  { id: "32", name: "Abu Qir", cityId: "4" },
  { id: "33", name: "El Azarita", cityId: "4" },
  { id: "34", name: "Al Ibrahimeyah", cityId: "4" },
  { id: "35", name: "Alexandria", cityId: "4" },
  { id: "36", name: "El Gomrok", cityId: "4" },
  { id: "37", name: "Al Hadara", cityId: "4" },
  { id: "38", name: "Al Dekheila", cityId: "4" },
  { id: "39", name: "El Soyof", cityId: "4" },
  { id: "40", name: "El Salheya", cityId: "4" },
  { id: "41", name: "Al Zahiriyyah", cityId: "4" },
  { id: "42", name: "Al Amreya", cityId: "4" },
  { id: "43", name: "Al Asafra ", cityId: "4" },
  { id: "44", name: "El Atareen ", cityId: "4" },
  { id: "45", name: "Al Awayed", cityId: "4" },
  { id: "46", name: "El Qabary", cityId: "4" },
  { id: "47", name: "El Labban", cityId: "4" },
  { id: "48", name: "Al Maamourah", cityId: "4" },
  { id: "49", name: "El Max", cityId: "4" },
  { id: "50", name: "El Montaza", cityId: "4" },
  { id: "51", name: "El Mandara", cityId: "4" },
  { id: "52", name: "El Mansheya", cityId: "4" },
  { id: "53", name: "El Nakheel", cityId: "4" },
  { id: "54", name: "El Nozha", cityId: "4" },
  { id: "55", name: "Al Wardian", cityId: "4" },
  { id: "56", name: "Bakos", cityId: "4" },
  { id: "57", name: "Bahri and El Anfoushy", cityId: "4" },
  { id: "58", name: "Borg El Arab ", cityId: "4" },
  { id: "59", name: "Bolkly", cityId: "4" },
  { id: "60", name: "Gleem", cityId: "4" },
  { id: "61", name: "Gianaclis", cityId: "4" },
  { id: "62", name: "Ras Al Tin", cityId: "4" },
  { id: "63", name: "Zezenia", cityId: "4" },
  { id: "64", name: "Saba Basha", cityId: "4" },
  { id: "65", name: "San Stefano", cityId: "4" },
  { id: "66", name: "Sporting", cityId: "4" },
  { id: "67", name: "Stanley", cityId: "4" },
  { id: "68", name: "Smouha", cityId: "4" },
  { id: "69", name: "Sidi Bishr", cityId: "4" },
  { id: "70", name: "Sidi Gaber", cityId: "4" },
  { id: "71", name: "Shatby", cityId: "4" },
  { id: "72", name: "Chutz", cityId: "4" },
  { id: "73", name: "Toson", cityId: "4" },
  { id: "74", name: "Agami", cityId: "4" },
  { id: "75", name: "Fleming", cityId: "4" },
  { id: "76", name: "Victoria", cityId: "4" },
  { id: "77", name: "Camp Shezar", cityId: "4" },
  { id: "78", name: "Karmoz", cityId: "4" },
  { id: "79", name: "Kafr Abdu", cityId: "4" },
  { id: "80", name: "Cleopatra", cityId: "4" },
  { id: "81", name: "Kom El Deka", cityId: "4" },
  { id: "82", name: "Louran", cityId: "4" },
  { id: "83", name: "Moharam Bek", cityId: "4" },
  { id: "84", name: "Mahatet El Raml", cityId: "4" },
  { id: "85", name: "Miami", cityId: "4" },
  { id: "86", name: "Mina El Basal", cityId: "4" },
  { id: "87", name: "Abo Swir", cityId: "5" },
  { id: "88", name: "Tell El Kebir", cityId: "5" },
  { id: "89", name: "El Kasasin", cityId: "5" },
  { id: "90", name: "Al Qantarah Sharq", cityId: "5" },
  { id: "91", name: "Al Qantarah Gharb", cityId: "5" },
  { id: "92", name: "Fayed", cityId: "5" },
  { id: "93", name: "Madinat El Ismailia", cityId: "5" },
  { id: "94", name: "Al Ghardaqah - Other", cityId: "6" },
  { id: "95", name: "Al Ghardaqah - El Gouna", cityId: "6" },
  { id: "96", name: "El Qoseir ", cityId: "6" },
  { id: "97", name: "Ras Gharib", cityId: "6" },
  { id: "98", name: "Safaga", cityId: "6" },
  { id: "99", name: "Sahl Hasheesh", cityId: "6" },
  { id: "100", name: "Shalateen", cityId: "6" },
  { id: "101", name: "Marsa Alam", cityId: "6" },
  { id: "102", name: "Abu El Matamir", cityId: "7" },
  { id: "103", name: "Abu Hummus", cityId: "7" },
  { id: "104", name: "Edku", cityId: "7" },
  { id: "105", name: "El Delengat", cityId: "7" },
  { id: "106", name: "El Rahmaniya", cityId: "7" },
  { id: "107", name: "El Mahmoudia", cityId: "7" },
  { id: "108", name: "Nubaria El Gadida", cityId: "7" },
  { id: "109", name: "Itay El Barud", cityId: "7" },
  { id: "110", name: "Badr", cityId: "7" },
  { id: "111", name: "Housh Eissa", cityId: "7" },
  { id: "112", name: "Damanhur ", cityId: "7" },
  { id: "113", name: "Rashid", cityId: "7" },
  { id: "114", name: "Shubra Khit", cityId: "7" },
  { id: "115", name: "Kafr El Dawwar", cityId: "7" },
  { id: "116", name: "Kom Hamada", cityId: "7" },
  { id: "117", name: "Wadi El Natrun", cityId: "7" },
  { id: "118", name: "6 October", cityId: "13" },
  { id: "119", name: "Abu Rawash", cityId: "13" },
  { id: "120", name: "Ausim", cityId: "13" },
  { id: "121", name: "Imbaba", cityId: "13" },
  { id: "122", name: "El Badrashein", cityId: "13" },
  { id: "123", name: "Al Barajil", cityId: "13" },
  { id: "124", name: "Al Haraneyah", cityId: "13" },
  { id: "125", name: "El Hawamdeya", cityId: "13" },
  { id: "126", name: "El Dokki", cityId: "13" },
  { id: "127", name: "Al Remaia", cityId: "13" },
  { id: "128", name: "El Sheikh Zayed", cityId: "13" },
  { id: "129", name: "El Sahafeyin", cityId: "13" },
  { id: "130", name: "El Saff", cityId: "13" },
  { id: "131", name: "Al Agouzah", cityId: "13" },
  { id: "132", name: "Al Azizeyah", cityId: "13" },
  { id: "133", name: "El Omraniya", cityId: "13" },
  { id: "134", name: "El Qaria El Faroinyah", cityId: "13" },
  { id: "135", name: "El kit kat", cityId: "13" },
  { id: "136", name: "Al Mansouria", cityId: "13" },
  { id: "137", name: "El Munib", cityId: "13" },
  { id: "138", name: "El Mohandessin", cityId: "13" },
  { id: "139", name: "Al Haram", cityId: "13" },
  { id: "140", name: "El Waraq", cityId: "13" },
  { id: "141", name: "Bashtil", cityId: "13" },
  { id: "142", name: "Bani Salamah", cityId: "13" },
  { id: "143", name: "Bolak Al Dakrour", cityId: "13" },
  { id: "144", name: "Tersa", cityId: "13" },
  { id: "145", name: "Jazirat Al Dhahab and Coldair", cityId: "13" },
  { id: "146", name: "Hadaiq Al Ahram", cityId: "13" },
  { id: "147", name: "Hay Al Giza ", cityId: "13" },
  { id: "148", name: "Dahshur", cityId: "13" },
  { id: "149", name: "Sakiat Mekki", cityId: "13" },
  { id: "150", name: "Saqqara", cityId: "13" },
  { id: "151", name: "Souk Al Ahad", cityId: "13" },
  { id: "152", name: "Saft", cityId: "13" },
  { id: "153", name: "Faisal", cityId: "13" },
  { id: "154", name: "Kerdasa", cityId: "13" },
  { id: "155", name: "Kafr Tohormos", cityId: "13" },
  { id: "156", name: "Markaz El Giza", cityId: "13" },
  { id: "157", name: "Al Marioteya", cityId: "13" },
  { id: "158", name: "Mit Okba", cityId: "13" },
  { id: "159", name: "Nahya", cityId: "13" },
  { id: "160", name: "Werdan", cityId: "13" },
  { id: "161", name: "Aga", cityId: "9" },
  { id: "162", name: "Ikhtab", cityId: "9" },
  { id: "163", name: "Al Jamaliyah", cityId: "9" },
  { id: "164", name: "El Senbellawein", cityId: "9" },
  { id: "165", name: "El Matareya", cityId: "9" },
  { id: "166", name: "El Manzala", cityId: "9" },
  { id: "167", name: "El Mansoura", cityId: "9" },
  { id: "168", name: "Belkas", cityId: "9" },
  { id: "169", name: "Bani Ebeid", cityId: "9" },
  { id: "170", name: "Timay Al Imdid", cityId: "9" },
  { id: "171", name: "Gamasa", cityId: "9" },
  { id: "172", name: "Dekernes", cityId: "9" },
  { id: "173", name: "Sherbin", cityId: "9" },
  { id: "174", name: "Talkha", cityId: "9" },
  { id: "175", name: "Menyet El Nasr", cityId: "9" },
  { id: "176", name: "Mit Selsil", cityId: "9" },
  { id: "177", name: "Mit Ghamr", cityId: "9" },
  { id: "178", name: "Nabaruh", cityId: "9" },
  { id: "180", name: "El Ein El Sokhna", cityId: "10" },
  { id: "181", name: "Hay Al Ganayen", cityId: "10" },
  { id: "182", name: "Hay Al Arbain", cityId: "10" },
  { id: "183", name: "Hay el Suez", cityId: "10" },
  { id: "184", name: "Hay Attaka", cityId: "10" },
  { id: "185", name: "Faisal", cityId: "10" },
  { id: "186", name: "Abu Hammad", cityId: "11" },
  { id: "187", name: "Abu kabir", cityId: "11" },
  { id: "188", name: "Awlad Saqr", cityId: "11" },
  { id: "189", name: "Al Ibrahimeyah", cityId: "11" },
  { id: "190", name: "El Husseiniya", cityId: "11" },
  { id: "191", name: "Zagazig", cityId: "11" },
  { id: "192", name: "Al Saleheyah El Gadidah", cityId: "11" },
  { id: "193", name: "Al Asher min Ramadan", cityId: "11" },
  { id: "194", name: "El Qurein", cityId: "11" },
  { id: "195", name: "Al Qanayat", cityId: "11" },
  { id: "196", name: "Belbeis", cityId: "11" },
  { id: "197", name: "Dyarb Negm", cityId: "11" },
  { id: "198", name: "Faqus", cityId: "11" },
  { id: "199", name: "Kafr Saqr", cityId: "11" },
  { id: "200", name: "Mashtul Al Suq", cityId: "11" },
  { id: "201", name: "Minya El Qamh", cityId: "11" },
  { id: "202", name: "Hihya", cityId: "11" },
  { id: "203", name: "Al Santa", cityId: "12" },
  { id: "204", name: "El Mahalla El Kubra", cityId: "12" },
  { id: "205", name: "Basyoun", cityId: "12" },
  { id: "206", name: "Zefta", cityId: "12" },
  { id: "207", name: "Samannud", cityId: "12" },
  { id: "208", name: "Tanta", cityId: "12" },
  { id: "209", name: "Qotour", cityId: "12" },
  { id: "210", name: "Kafr El Zayyat", cityId: "12" },
  { id: "211", name: "El Ameeriah", cityId: "13" },
  { id: "212", name: "El Basatin", cityId: "13" },
  { id: "213", name: "Al Tbin", cityId: "13" },
  { id: "214", name: "El Tagamoa El Talt", cityId: "13" },
  { id: "215", name: "El Gamaleya", cityId: "13" },
  { id: "216", name: "Al Helmiya", cityId: "13" },
  { id: "217", name: "Al Helmiya Al Gadidah", cityId: "13" },
  { id: "218", name: "El Khaleefa", cityId: "13" },
  { id: "219", name: "Al Darrasa", cityId: "13" },
  { id: "220", name: "El Darb El-Ahmar", cityId: "13" },
  { id: "221", name: "El Rawda", cityId: "13" },
  { id: "222", name: "El Zawya El Hamraa", cityId: "13" },
  { id: "223", name: "Al zamalek", cityId: "13" },
  { id: "224", name: "El Zaytoun", cityId: "13" },
  { id: "225", name: "El Sahel", cityId: "13" },
  { id: "226", name: "Al Sayeda Zainab", cityId: "13" },
  { id: "227", name: "Al Sharabiya", cityId: "13" },
  { id: "228", name: "Al Asher min Ramadan", cityId: "13" },
  { id: "229", name: "El Aasma El Edarya Al Gadidah", cityId: "13" },
  { id: "230", name: "El Abaseya", cityId: "13" },
  { id: "231", name: "El Obour ", cityId: "13" },
  { id: "232", name: "El Ataba", cityId: "13" },
  { id: "233", name: "Al Qahira - Other", cityId: "13" },
  { id: "234", name: "Al Qahira El Gadidah - El Tagamoa El Awl", cityId: "13" },
  { id: "235", name: "Al Qahira El Gadidah - Other", cityId: "13" },
  { id: "236", name: "Al Qahira El Gadidah - El Andalos ", cityId: "13" },
  {
    id: "237",
    name: "Al Qahira El Gadidah - El Tagamoa El Khames",
    cityId: "13",
  },
  { id: "238", name: "Al Qahira El Gadidah - Lotus", cityId: "13" },
  { id: "239", name: "Al Qahira El Gadidah - Madinat El Rehab", cityId: "13" },
  { id: "240", name: "El Katameya", cityId: "13" },
  { id: "241", name: "Al mazah", cityId: "13" },
  { id: "242", name: "El Marg", cityId: "13" },
  { id: "243", name: "Al Matariyyah", cityId: "13" },
  { id: "244", name: "Al Maadi", cityId: "13" },
  { id: "245", name: "Al Maasarah", cityId: "13" },
  { id: "246", name: "El Mukkatam", cityId: "13" },
  { id: "247", name: "El Mounera El Gadidah", cityId: "13" },
  { id: "248", name: "Al Manial", cityId: "13" },
  { id: "249", name: "Al Moesky", cityId: "13" },
  { id: "250", name: "El Nozha", cityId: "13" },
  { id: "251", name: "Al Waili", cityId: "13" },
  { id: "252", name: "Bab Al Shariyah", cityId: "13" },
  { id: "253", name: "Bulaq", cityId: "13" },
  { id: "254", name: "Bayt Al Watan", cityId: "13" },
  { id: "255", name: "Garden City", cityId: "13" },
  { id: "256", name: "Hadayek Al Qubbah", cityId: "13" },
  { id: "257", name: "Hadayek Helwan", cityId: "13" },
  { id: "258", name: "Helwan", cityId: "13" },
  { id: "259", name: "Dar El Salam", cityId: "13" },
  { id: "260", name: "Ramses and Emtedad Ramses", cityId: "13" },
  { id: "261", name: "Rod El Farag", cityId: "13" },
  { id: "262", name: "Zahraa Al Maadi", cityId: "13" },
  { id: "263", name: "Shubra", cityId: "13" },
  { id: "264", name: "Sheraton", cityId: "13" },
  { id: "265", name: "Tura", cityId: "13" },
  { id: "266", name: "Ezbet El Nakhl", cityId: "13" },
  { id: "267", name: "Ain Shams", cityId: "13" },
  { id: "268", name: "Qasr El Nil", cityId: "13" },
  { id: "269", name: "Madinat 15 Mayo", cityId: "13" },
  { id: "270", name: "Madinat El Salam", cityId: "13" },
  { id: "271", name: "Madinat El Shorouk", cityId: "13" },
  { id: "272", name: "Madinat El Mostakbal", cityId: "13" },
  { id: "273", name: "Madinat Badr", cityId: "13" },
  { id: "274", name: "Madinat Nasr", cityId: "13" },
  { id: "275", name: "Madinaty", cityId: "13" },
  { id: "276", name: "Masr Al Gadidah", cityId: "13" },
  { id: "277", name: "Masr El Adima", cityId: "13" },
  { id: "278", name: "Heliopolis Al Gadidah ", cityId: "13" },
  { id: "279", name: "Wist Al Qahira", cityId: "13" },
  { id: "280", name: "Al Khankah", cityId: "14" },
  { id: "281", name: "Al Khusus", cityId: "14" },
  { id: "282", name: "El Obour", cityId: "14" },
  { id: "283", name: "El Qanater El Khayreya", cityId: "14" },
  { id: "284", name: "Banha", cityId: "14" },
  { id: "285", name: "Bahtim", cityId: "14" },
  { id: "286", name: "Shubra El Kheima", cityId: "14" },
  { id: "287", name: "Shibin El Qanater", cityId: "14" },
  { id: "288", name: "Toukh", cityId: "14" },
  { id: "289", name: "Qalyub", cityId: "14" },
  { id: "290", name: "Qaha", cityId: "14" },
  { id: "291", name: "Kafr Shukr", cityId: "14" },
  { id: "292", name: "Ashmun", cityId: "15" },
  { id: "293", name: "El Bagour", cityId: "15" },
  { id: "294", name: "El Sadat", cityId: "15" },
  { id: "295", name: "Al Shuhada", cityId: "15" },
  { id: "296", name: "Birkat al SAB", cityId: "15" },
  { id: "297", name: "Tala", cityId: "15" },
  { id: "298", name: "Sirs Al Layyanah", cityId: "15" },
  { id: "299", name: "Shibin El Kom", cityId: "15" },
  { id: "300", name: "Quesna", cityId: "15" },
  { id: "301", name: "Minuf", cityId: "15" },
  { id: "302", name: "Mit El Ezz", cityId: "15" },
  { id: "303", name: "Abu Qurqas", cityId: "16" },
  { id: "304", name: "El Idwa", cityId: "16" },
  { id: "305", name: "El Minya El Gadidah", cityId: "16" },
  { id: "306", name: "Beni Mazar", cityId: "16" },
  { id: "307", name: "Deir Mawas", cityId: "16" },
  { id: "308", name: "Samalut", cityId: "16" },
  { id: "309", name: "Madinat El Minya", cityId: "16" },
  { id: "310", name: "Matay", cityId: "16" },
  { id: "311", name: "Maghagha", cityId: "16" },
  { id: "312", name: "Mallawi", cityId: "16" },
  { id: "313", name: "El Kharga", cityId: "17" },
  { id: "314", name: "El Dakhla", cityId: "17" },
  { id: "315", name: "Farafra", cityId: "17" },
  { id: "316", name: "Baris", cityId: "17" },
  { id: "317", name: "Balat", cityId: "17" },
  { id: "318", name: "Madinat Mut", cityId: "17" },
  { id: "319", name: "Ehnasia", cityId: "18" },
  { id: "320", name: "El Fashn", cityId: "18" },
  { id: "321", name: "Al Wasta", cityId: "18" },
  { id: "322", name: "Biba", cityId: "18" },
  { id: "323", name: "Bani Suef El Gadidah", cityId: "18" },
  { id: "324", name: "Samasta", cityId: "18" },
  { id: "325", name: "Madinat Beni Suef", cityId: "18" },
  { id: "326", name: "Nasser", cityId: "18" },
  { id: "327", name: "Hay El Ganoub", cityId: "19" },
  { id: "328", name: "Hay Al Zehour", cityId: "19" },
  { id: "329", name: "Hay El Sharq", cityId: "19" },
  { id: "330", name: "Hay El Dawahy", cityId: "19" },
  { id: "331", name: "Hay Al Arab", cityId: "19" },
  { id: "332", name: "Hay Al Manakh", cityId: "19" },
  { id: "333", name: "Madinat Port Fuad", cityId: "19" },
  { id: "334", name: "Abou Redis", cityId: "20" },
  { id: "335", name: "Abu Zenima", cityId: "20" },
  { id: "336", name: "Dahab", cityId: "20" },
  { id: "337", name: "Ras Sedr", cityId: "20" },
  { id: "338", name: "Saint Catherine", cityId: "20" },
  { id: "339", name: "Sharm El Sheikh", cityId: "20" },
  { id: "340", name: "Taba", cityId: "20" },
  { id: "341", name: "Tur Sinai", cityId: "20" },
  { id: "342", name: "Nuweiba", cityId: "20" },
  { id: "343", name: "El Rawda", cityId: "21" },
  { id: "344", name: "El Zarqa", cityId: "21" },
  { id: "345", name: "Al Sirw", cityId: "21" },
  { id: "346", name: "Damietta El Gadidah ", cityId: "21" },
  { id: "347", name: "Ras El Bar", cityId: "21" },
  { id: "348", name: "Izbat Al Burj", cityId: "21" },
  { id: "349", name: "Faraskur", cityId: "21" },
  { id: "350", name: "Kafr El Battikh", cityId: "21" },
  { id: "351", name: "Kafr Saad", cityId: "21" },
  { id: "352", name: "Madinat Damiett", cityId: "21" },
  { id: "353", name: "Mit Abou Ghaleb", cityId: "21" },
  { id: "354", name: "Akhmim", cityId: "22" },
  { id: "355", name: "El Balyana", cityId: "22" },
  { id: "356", name: "El Usayrat", cityId: "22" },
  { id: "357", name: "El Maragha", cityId: "22" },
  { id: "358", name: "Al Minshah", cityId: "22" },
  { id: "359", name: "Girga", cityId: "22" },
  { id: "360", name: "Juhayna", cityId: "22" },
  { id: "361", name: "Dar El Salam", cityId: "22" },
  { id: "362", name: "Saqultah", cityId: "22" },
  { id: "363", name: "Tima", cityId: "22" },
  { id: "364", name: "Tahta", cityId: "22" },
  { id: "365", name: "Madinat Sohag", cityId: "22" },
  { id: "366", name: "Al Hasna", cityId: "23" },
  { id: "367", name: "El Sheikh Zuweid", cityId: "23" },
  { id: "368", name: "Al Arish", cityId: "23" },
  { id: "369", name: "Bir El Abd", cityId: "23" },
  { id: "370", name: "Rafah", cityId: "23" },
  { id: "371", name: "Nekhel", cityId: "23" },
  { id: "372", name: "Abu Tesht", cityId: "24" },
  { id: "373", name: "Al Waqf", cityId: "24" },
  { id: "374", name: "Dishna", cityId: "24" },
  { id: "375", name: "Farshut", cityId: "24" },
  { id: "376", name: "Qift", cityId: "24" },
  { id: "377", name: "Qus", cityId: "24" },
  { id: "378", name: "Madinat Qena", cityId: "24" },
  { id: "379", name: "Nagaa Hammadi", cityId: "24" },
  { id: "380", name: "Naqada", cityId: "24" },
  { id: "381", name: "El Burullus", cityId: "25" },
  { id: "382", name: "El Hamool", cityId: "25" },
  { id: "383", name: "El Reyad", cityId: "25" },
  { id: "384", name: "Baltim", cityId: "25" },
  { id: "385", name: "Biyala", cityId: "25" },
  { id: "386", name: "Desouk", cityId: "25" },
  { id: "387", name: "Sidi Salem", cityId: "25" },
  { id: "388", name: "Fuwwah", cityId: "25" },
  { id: "389", name: "Qallin", cityId: "25" },
  { id: "390", name: "Madinat Kafr El Sheikh", cityId: "25" },
  { id: "391", name: "Mutubas", cityId: "25" },
  { id: "392", name: "El Hamam", cityId: "26" },
  { id: "393", name: "El Sahel El Shamaly", cityId: "26" },
  { id: "394", name: "El Salloum", cityId: "26" },
  { id: "395", name: "El Dabaa", cityId: "26" },
  { id: "396", name: "El Alamein", cityId: "26" },
  { id: "397", name: "El Negaila", cityId: "26" },
  { id: "398", name: "Barrani", cityId: "26" },
  { id: "399", name: "Siwa", cityId: "26" },
  { id: "400", name: "Marina El Alamein", cityId: "26" },
  { id: "401", name: "Marsa Matruh ", cityId: "26" },
];

const CurrencyOptions = [
  {
    id: "1",
    name: "EGP",
  },
  {
    id: "2",
    name: "USD",
  },
  {
    id: "3",
    name: "SAR",
  },
  {
    id: "4",
    name: "AED",
  },
  {
    id: "5",
    name: "EUR",
  },
];

export default {
  UserStatusOptions,
  UserRolesFilterOptions,
  UserRolesOptions,
  PickupsFilterOptions,
  Cities,
  Areas,
  CurrencyOptions,
};
