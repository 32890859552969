import gql from "graphql-tag";
export const paginationFragment = gql`
  fragment pagination on PaginatorInfo {
    count
    lastItem
    total
    perPage
    currentPage
    hasMorePages
    firstItem
    lastPage
  }
`;

export const userFragment = gql`
  fragment userData on User {
    id
    name
    email
    phone
    role {
      name
      id
    }
    status {
      name
    }
  }
`;

export const donorFragment = gql`
  fragment donorData on Donor {
    id
    name
    phone
    donations {
      currency_name
      amount
    }
    address {
      id
      name
      area {
        name
        city {
          name
        }
      }
    }
  }
`;

export const pickupDonorFragment = gql`
  fragment pickupDonorData on Donor {
    id
    name
    phone
    address {
      id
      name
      area {
        name
        city {
          name
        }
      }
    }
    addresses {
      id
      name
      area {
        name
        city {
          name
        }
      }
    }
  }
`;
export const donorProfileFragment = gql`
  fragment donorProfile on Donor {
    id
    name
    phone
    alt_phone
    email
    note
    address {
      id
    }
    donations {
      currency_name
      amount
    }
  }
`;

export const collectorFragment = gql`
  fragment collectorData on User {
    name
    id
    email
    phone
    role {
      id
    }
    balances {
      currency_name
      currency_id
      amount
    }
  }
`;

export const collectionFragment = gql`
  fragment collectionData on Payment {
    name
    id
    type {
      id
      name
    }
    pickupType {
      id
      name
    }
    collection_date
    donor {
      name
      id
    }
    has_cash
    has_material
    material_note
    transactions {
      id
      amount
      balance
      currency {
        name
        id
      }
    }
    address {
      area {
        name
        city {
          name
        }
      }
    }
    created_at
  }
`;

export const pickupFragment = gql`
  fragment pickupData on Payment {
    name
    id
    contact_date
    collection_date
    pickupType {
      id
    }
    donor {
      name
      id
      addresses {
        id
        name
        area {
          name
          city {
            name
          }
        }
      }
    }
    collector {
      id
      name
    }
    status {
      id
      name
    }
    cancellation_note
    main_note
    schedule_note
    address {
      id
      area {
        name
        city {
          name
        }
      }
    }
    created_at
  }
`;
export const donorPickupFragment = gql`
  fragment donorPickupData on Payment {
    name
    id
    contact_date
    collection_date
    collector {
      id
      name
    }
    status {
      id
      name
    }
    cancellation_note
    main_note
    schedule_note
    address {
      area {
        name
        city {
          name
        }
      }
    }
    created_at
  }
`;
