import React from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const ProtectedRouter = ({ userRole, roles = [], children, ...props }) => {
  if (roles.includes(userRole?.id)) {
    return <Route {...props}>{children}</Route>;
  } else {
    return (
      <Route {...props}>
        <Redirect to="/unauthorized" />
      </Route>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth?.userProfile?.role,
  };
};

export default connect(mapStateToProps)(ProtectedRouter);
