import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PickupAddresses from "../PickupAddresses";
import TotalDonations from "../TotalDonations";
import { fetchDonorAction } from "../../Store/Actions";

const DonorInfoCard = ({ donorProfile, ...props }) => {
  const { donorId } = useParams();

  const handleEditDonor = () => {
    props.fetchDonorAction(donorId);
  };
  return (
    <>
      <div className="donor_profile_wrapper_style my-2">
        <div className="card_wrapper_style pt-4 px-3 px-md-4 pb-3 ">
          <div className="row">
            <div className="col-12 col-lg-2">
              <h1>{donorProfile.name}</h1>
              <p>{donorProfile.phone}</p>
              <p>{donorProfile.alt_phone}</p>
              <p>{donorProfile.email}</p>
            </div>

            <div className="col-12 col-lg-5 py-3 py-md-0">
              <label>Pickup Addresses</label>
              <PickupAddresses pickup_address_data={donorProfile.addresses} />
            </div>

            <div className="col-12 col-lg-4">
              {props.user.role.id == "1" ? (
                <>
                  <label>Total Donations</label>
                  <TotalDonations
                    total_donations_data={donorProfile.donations}
                  />
                </>
              ) : null}
            </div>

            <div className="col-12 col-lg-1 text-right pt-2 py-lg-0">
              <button
                className="btn edit_btn_style"
                type="button"
                onClick={handleEditDonor}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
        {Boolean(donorProfile.note) && (
          <div className="donor_profile_wrapper_style my-3">
            <div className="card_wrapper_style py-2 px-3 px-md-4">
              <label className="h5">Notes</label>
              <p>{donorProfile.note}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    donorProfile: state.super.donorProfile,
    user: state.auth.userProfile,
  };
};

export default connect(mapStateToProps, { fetchDonorAction })(DonorInfoCard);
