import React, { useEffect } from "react";
import {
  faPencilAlt,
  faPowerOff,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";

import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Store from "../../Store";

import DonorsSearchCard from "../../Components/DonorsSearchCard";
import Pagination from "../../Components/Pagination";
import {
  fetchAllDonorsAction,
  setDataTableSortingAction,
  onInputResetAction,
  showDonorFormAction,
  fetchDonorAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import Loader from "../../Components/Loader";

const DonorsList = (props) => {
  const dataTableRef = "allDonorsList";
  const columns = [
    {
      name: "Name",
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1.2,
      cell: (row) => <Link to={`donor-profile/${row?.id}`}>{row?.name}</Link>,
    },
    {
      name: "phone number",
      wrap: true,
      selector: "phone",
      sortable: false,
      grow: 0.8,
    },
    {
      name: "address",
      wrap: true,
      selector: "address",
      sortable: false,
      grow: 1.2,
      cell: (row) => `${row.address?.name}`,
    },
    {
      name: "total dontations",
      wrap: true,
      selector: "donations",
      sortable: false,
      grow: 0.8,
      omit: props.user?.role?.id !== "1" ? true : false,
      cell: (row) => (
        <div>
          {row?.donations.map((donation) => (
            <div>
              {donation?.amount} {donation?.currency_name}
            </div>
          ))}
        </div>
      ),
    },
    {
      name: "area",
      wrap: true,
      selector: "address",
      sortable: false,
      grow: 0.5,
      cell: (row) => `${row.address?.area?.name}`,
    },
    {
      name: "city",
      wrap: true,
      selector: "status",
      sortable: false,
      grow: 0.5,
      cell: (row) => `${row.address?.area?.city?.name}`,
    },
    {
      name: "Actions",
      wrap: true,
      selector: "action",
      // right: true,
      grow: 0.5,
      cell: ({ role, status, ...row }) => (
        <div className="general-data-table-action">
          <span
            className="btn"
            onClick={() => Store.dispatch(fetchDonorAction(row.id))}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </span>
          {/* {status.name == "Inactive" ? (
            <span className="btn" onClick={() => console.log(row)}>
              <FontAwesomeIcon icon={faPlay} />
            </span>
          ) : (
            <span className="btn" onClick={() => console.log(row)}>
              <FontAwesomeIcon icon={faPowerOff} />
            </span>
          )} */}
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    // props.fetchAllDonorsAction({
    //   ref: dataTableRef,
    //   [dataTableRef]: props.data[dataTableRef],
    // });
    return () => {
      props.onInputResetAction(dataTableRef, "name");
      props.onInputResetAction(dataTableRef, "phone");
      props.onInputResetAction(dataTableRef, "city_id");
      props.onInputResetAction(dataTableRef, "area_id");
    };
  }, []);

  const handleFilter = () => {
    props.fetchAllDonorsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };
  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].name,
    props.data[dataTableRef].phone,
    props.data[dataTableRef].city_id,
    props.data[dataTableRef].area_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchAllDonorsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  const handleSorting = (field, dir) => {
    props.fetchAllDonorsAction({
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
        sorting: {
          dir,
          key: field.selector,
        },
      },
      ref: dataTableRef,
    });
    props.setDataTableSortingAction(dataTableRef, field.selector, dir);
  };
  return (
    <div>
      <DonorsSearchCard formName={dataTableRef} />
      <div className="mt-3">
        <DataTable
          className="general-data-list donor_list_wrapper_style"
          columns={columns}
          data={props.data[dataTableRef]?.data}
          noHeader
          persistTableHead
          sortServer
          onSort={handleSorting}
          defaultSortAsc={props.data[dataTableRef].sorting.dir == "asc"}
          defaultSortField={props.data[dataTableRef].sorting.key}
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=""
              onPaginate={handlePaginate}
            />
          )}
          pagination={true}
          paginationServer={true}
          progressPending={props.data[dataTableRef].isLoading}
          progressComponent={<Loader />}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    user: state.auth.userProfile,
  };
};

export default connect(mapStateToProps, {
  fetchAllDonorsAction,
  setDataTableSortingAction,
  onInputResetAction,
  showDonorFormAction,
})(DonorsList);
