const paginatorInfo = {
  lastItem: 0,
  total: 0,
  perPage: 30,
  currentPage: 1,
  hasMorePages: true,
  firstItem: 1,
  lastPage: 1,
};

const userForm = {
  id: "",
  name: "",
  phone: "",
  email: "",
  role_id: "",
};

const addressInput = {
  id: "",
  name: "",
  map_link: "",
  city_id: "13",
  area_id: "",
  default_address: 0,
};

const donorForm = {
  id: "",
  name: "",
  phone: "",
  alt_phone: "",
  email: "",
  note: "",
};

const usersList = {
  ids: [],
  email: "",
  phone: "",
  status_id: null,
  from: "",
  to: "",
  role_id: null,
  pagination: { ...paginatorInfo },
  data: [],
  sorting: {
    key: "name",
    dir: "asc",
  },
  isLoading: false,
};

const pickupForm = {
  donor: {
    name: "",
    id: "",
    addresses: [],
  },
  id: "",
  donor_id: null,
  contact_date: "",
  collector_id: "",
  main_note: "",
  address_id: "",
  collection_date: null,
  pickup_type_id: "1",
};

const pickupAddressForm = {
  pickup_address_options: [],
  new_pickup_address: "",
};

const pickupsList = {
  donor_id: "",
  collector_id: "",
  donor_phone: "",
  name: "",
  collection_date_from: "",
  collection_date_to: "",
  area_id: [],
  city_id: [],
  status_id: null,
  time_range: "All",
  pagination: { ...paginatorInfo },
  data: [],
  sorting: {
    column: "name",
    order: "desc",
  },
  isLoading: false,
};

const donorsList = {
  name: "",
  phone: "",
  area_id: [],
  city_id: [],
  pagination: { ...paginatorInfo },
  data: [],
  sorting: {
    key: "name",
    dir: "asc",
  },
  isLoading: false,
};

const collectorProfile = {
  id: "",
  name: "",
  phone: "",
  email: "",
  balances: [],
  isLoading: false,
};

const collections = {
  name: "",
  phone: "",
  area_id: [],
  city_id: [],
  pagination: { ...paginatorInfo },
  data: [],
  sorting: {
    column: "collection_date",
    order: "desc",
  },
  isLoading: false,
};

const donorPickups = {
  collector_id: "",
  name: "",
  date_from: "",
  date_to: "",
  area_id: [],
  city_id: [],
  status_id: null,
  pagination: { ...paginatorInfo },
  data: [],
  sorting: {
    key: "name",
    dir: "asc",
  },
  isLoading: false,
};

const addBalanceForm = {
  id: null,
  collector_id: null,
  type_id: "Add_Balance",
  transactions: [
    {
      id: null,
      amount: 0,
      currency_id: "1",
    },
  ],
};

export default {
  userForm,
  pickupForm,
  pickupAddressForm,
  paginatorInfo,
  usersList,
  donorsList,
  pickupsList,
  collectorProfile,
  collections,
  donorForm,
  addressInput,
  donorPickups,
  addBalanceForm,
};
