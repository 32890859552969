import React, { useState } from "react";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Helper from "../../Helpers/HelperFns";
import { NavLink, BrowserRouter as Router } from "react-router-dom";
import { logoutAction } from "../../Store/Actions";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const MerqahNavbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar expand="md">
        <NavbarBrand className="logo_wrapper_style">
          <img
            src={require("../../Assets/img/ataya_logo.png").default}
            className="img-fluid"
          />
        </NavbarBrand>
        <NavbarToggler onClick={handleToggleDropdown} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="w-100" navbar>
            {["1","5"].includes(props?.user?.role?.id) ? (
              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/users"
                  activeClassName="active"
                >
                  users
                </NavLink>
              </NavItem>
            ) : null}
            {props?.user?.role?.id !== "5" ?

           (<><NavItem>
                         <NavLink
                           className="nav-link"
                           to="/pickups"
                           activeClassName="active"
                         >
                           pickups
                         </NavLink>
                       </NavItem>
                       <NavItem className="mr-lg-auto">
                         <NavLink
                           className="nav-link"
                           to="/donors"
                           activeClassName="active"
                         >
                           donors
                         </NavLink>
                       </NavItem>
                       </>)
            :null}
            <UncontrolledDropdown className="ml-md-auto" inNavbar>
              <DropdownToggle nav>
                <Avatar
                  // src={props?.img_path}
                  className="rounded_circle_user_img"
                >
                  {Helper.getIntials(props?.user?.name)}
                </Avatar>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => props.logoutAction()}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userProfile,
  };
};

export default connect(mapStateToProps, { logoutAction })(MerqahNavbar);
