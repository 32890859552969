import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from "reactstrap";
import classnames from "classnames";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import "../../Builder/Form/InputFormStyle.scss";
import Const from "../../Constants";

import { onInputChangeAction, showUserFormAction } from "../../Store/Actions";

import { InputForm, BSelect } from "../../Builder/Form";

const UsersSearchCard = (props) => {
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (props?.authUser?.role?.id === "5") {
      handleRoleFilter("Collector");
    }

    return () => {
      handleRoleFilter(null);
    };
  }, [props?.authUser?.role?.id]);

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleRoleFilter = (val) => {
    let e = {
      target: {
        name: "role_id",
        value: val,
      },
    };
    props.onInputChangeAction(props.formName, e);
  };

  return (
    <div className="custom_nav_tabs_wrapper_style card_wrapper_style pt-2">
      <div className="d-flex justify-content-between">
        <div>
          <Nav className="flex-column flex-md-row" tabs>
            {props.authUser?.role?.id === "5" ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: true,
                  })}
                  onClick={() => handleRoleFilter("Collector")}
                >
                  Collector
                </NavLink>
              </NavItem>
            ) : (
              Const.UserRolesFilterOptions.map((role) => (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: role.value === props.data[props.formName].role_id,
                    })}
                    onClick={() => handleRoleFilter(role.value)}
                  >
                    {role.label}
                  </NavLink>
                </NavItem>
              ))
            )}
          </Nav>
        </div>

        <div>
          <div className="d-flex">
            <div>
              <label
                className="btn search_btn_style mr-2"
                type="button"
                onClick={handleToggleFilter}
              >
                <SearchOutlinedIcon />
              </label>
            </div>
            {props.authUser?.role?.id == "1" ? (
              <div>
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  onClick={() => props.showUserFormAction("New", null)}
                >
                  <PersonAddOutlinedIcon />
                  <p>Add User</p>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Collapse isOpen={showFilter}>
        <div className="mt-3 pl-2 pl-md-0">
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <BSelect
                name="ids"
                formName={props.formName}
                keepDefaultStyle
                inputContainerStyle="select-def-input-container"
                containerStyle=" "
                rootStyle=""
                isMulti
                optionLabel="name"
                optionValue="id"
                options={props.data?.usersFilterOptions}
              />
            </div>

            <div className="col-12 col-md-6 mb-2">
              <InputForm
                name="email"
                placeholder="Email"
                containerStyle=" "
                inputStyle="input-style-default"
                formName={props.formName}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <InputForm
                name="phone"
                placeholder="User Phone Number"
                containerStyle=" "
                inputStyle="input-style-default"
                formName={props.formName}
              />
            </div>

            <div className="col-12 col-md-6 mb-2">
              <BSelect
                name="status_id"
                formName={props.formName}
                keepDefaultStyle
                inputContainerStyle="select-def-input-container"
                containerStyle=" "
                rootStyle=""
                isMulti={false}
                optionLabel="label"
                optionValue="value"
                options={Const.UserStatusOptions}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    authUser: state.auth.userProfile,
  };
};

export default connect(mapStateToProps, {
  onInputChangeAction,
  showUserFormAction,
})(UsersSearchCard);
